export const SET_INIT_ROUTE = 'SET_INIT_ROUTE';
export const SET_DETOUR = 'SET_DETOUR';
export const SET_TIME = 'SET_TIME';
export const SET_TYPE = 'SET_TYPE';
export const SET_DAYS = 'SET_DAYS';
export const SET_CAP = 'SET_CAP';
export const SET_ID = 'SET_ID';
export const SET_UID = 'SET_UID';
export const SET_ROUTE_DATE_RANGE = 'SET_ROUTE_DATE_RANGE';
export const SET_ROUTE_DEPARTURE_ADDRESS = 'SET_ROUTE_DEPARTURE_ADDRESS';
export const SET_ROUTE_DESTINATION_ADDRESS = 'SET_ROUTE_DESTINATION_ADDRESS';
export const SET_ROUTE_STATUS = 'SET_ROUTE_STATUS';
export const SET_ROUTE = 'SET_ROUTE';
