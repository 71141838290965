export const SET_OBJECT = 'SET_OBJECT';
export const SET_ADRESS = 'SET_ADRESS';
export const SET_PRICE = 'SET_PRICE';
export const SET_TIME = 'SET_TIME';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const ADD_IMAGE = 'ADD_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const SET_ID = 'SET_ID';
export const SET_UID = 'SET_UID';
export const SET_REQ_PICKUP_ADDRESS = 'SET_REQ_PICKUP_ADDRESS';
export const SET_REQ_DELIVERY_ADDRESS = 'SET_REQ_DELIVERY_ADDRESS';
export const SET_STATUS = 'SET_STATUS';
export const SET_DEAL_ID = 'SET_DEAL_ID';
export const SET_REQUEST = 'SET_REQUEST';
